import { render, staticRenderFns } from "./video.vue?vue&type=template&id=781839ae"
import script from "./video.vue?vue&type=script&lang=js"
export * from "./video.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/merdy/Sites/Symfony/bookfolio/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('781839ae')) {
      api.createRecord('781839ae', component.options)
    } else {
      api.reload('781839ae', component.options)
    }
    module.hot.accept("./video.vue?vue&type=template&id=781839ae", function () {
      api.rerender('781839ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/vuejs/components/portfolio/video.vue"
export default component.exports