var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("go-top", {
        attrs: {
          "bg-color": "#36b5aa",
          src: "/assets/img/icon-backtotop.png",
          size: 50,
          "has-outline": false,
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.videos, function (video, $index) {
        return _c(
          "div",
          {
            key: $index,
            staticClass: "col-lg-6",
            staticStyle: { "margin-bottom": "30px" },
          },
          [_c("video-embed", { attrs: { src: video.url } })],
          1
        )
      }),
      _vm._v(" "),
      _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }, [
        _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { slot: "no-results" }, slot: "no-results" },
          [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("div", { staticClass: "alert alert-warning" }, [
                        _vm._v(
                          "\n              Aucune vidéo pour l'instant.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }