import { render, staticRenderFns } from "./ViewerImage.vue?vue&type=template&id=7fe7687e&scoped=true"
import script from "./ViewerImage.vue?vue&type=script&lang=js"
export * from "./ViewerImage.vue?vue&type=script&lang=js"
import style0 from "../components/modal.css?vue&type=style&index=0&id=7fe7687e&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fe7687e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/merdy/Sites/Symfony/bookfolio/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7fe7687e')) {
      api.createRecord('7fe7687e', component.options)
    } else {
      api.reload('7fe7687e', component.options)
    }
    module.hot.accept("./ViewerImage.vue?vue&type=template&id=7fe7687e&scoped=true", function () {
      api.rerender('7fe7687e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/vuejs/components/ViewerImage.vue"
export default component.exports