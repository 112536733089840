<template>
  <div>

    <b-modal ref="imageModal" id="scroll" scrollable centered size="xl" hide-footer @hide="handleModalClose">
      <template #modal-header>
        <button href="javascript:void(0)" class="close-btn" @click="$refs.imageModal.hide()">
          <b-icon icon="x" aria-label="Close" variant="dark"></b-icon>
        </button>
        <v-container>
          <v-row justify="space-between">
            <v-col :cols="getTitleCols()" sm="12" :md="getTitleCols()" class="text-start">
              <b-media class="d-flex">
                <template #aside>
                  <b-avatar button rounded="sm" :src="selectedImage.user.avatar"></b-avatar>
                </template>
                <div class="lh-sm">
                  <h5 class="m-0 fs-6 lh-sm">{{ selectedImage.user.fullName }}</h5>
                  <small class="m-0 text-muted">{{ selectedImage.user.profession }} - {{
                      selectedImage.user.location
                    }}</small>
                </div>
              </b-media>
            </v-col>
            <template v-if="selectedImage.title">
              <v-col :cols="getTitleCols()" sm="12" :md="getTitleCols()" class="text-center" style="white-space: nowrap;">
                <h5 class="m-0 p-0 fs-6">{{ selectedImage.title }}</h5>
              </v-col>
            </template>
            <v-col :cols="getTitleCols()" sm="12" :md="getTitleCols()" class="text-end btn-actions">
              <template v-if="selectedImage.allowComments">
                <b-button variant="light" @click="getAnchorLink('imageDown')">
                  <b-icon icon="chat-left-dots" font-scale="0.9" class="me-1" aria-label="Like" variant="dark"></b-icon>
                  {{ selectedImage.countComments }}
                  <template v-if="selectedImage.countComments > 1">commentaires</template>
                  <template v-else>commentaire</template>
                </b-button>
              </template>
              <b-button variant="light" @click="getAnchorLink('imageDown')">
                <b-icon icon="info-circle" font-scale="0.9" class="me-1" aria-label="Like" variant="dark"></b-icon>
                Infos
              </b-button>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <viewer-image-common :selectedImage="selectedImage"></viewer-image-common>
      <div id="scroll-imageDown"></div>
    </b-modal>
  </div>
</template>


<script>
import VLazyImage from "v-lazy-image";

const routes = require("../../../public/js/fos_js_routes.json");
import Routing from "../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Routing.setRoutingData(routes);
import axios from "axios";
import ViewerImageCommon from "../components/ViewerImageCommon.vue";

export default {
  props: ["image"],
  components: {
    "viewer-image-common": ViewerImageCommon,
  },
  name: "ViewerImage.vue",
  data() {
    return {
      imagesId: [], // Initialize as an empty array
      selectedImage: null,
    };
  },
  mounted() {
    const image = this.image;
    if (image) {
      this.openModal(image)
    }
  },
  methods: {
    getTitleCols() {
      return this.selectedImage.title ? 4 : 6;
    },

    handleModalClose() {
      // Revert the URL back to its original state using the router's replace method
      const baseUrl = this.$router.currentRoute.path.split('/image')[0]; // Obtenir l'URL de base en supprimant '/image' et tout ce qui suit
      this.$router.replace({path: baseUrl});
      this.enableBodyScroll();
    },
    openModal(image) {
      this.selectedImage = JSON.parse(image);
      this.$refs.imageModal.show();
      this.disableBodyScroll();
      $.ajax({
        url: Routing.generate("portfolio_setviewmedias", {
          name: name,
          image: this.selectedImage.id,
        }),
        type: "POST",
        data: this.selectedImage.id,
        success: function (response) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(textStatus, errorThrown);
        },
      });
    },
    disableBodyScroll() {
      document.body.style.overflow = "hidden";
    },
    enableBodyScroll() {
      document.body.style.overflow = "auto";
    },
    getAnchorLink(anchorId) {
      const anchor = document.getElementById(`${this.$refs.imageModal.id}-${anchorId}`);
      if (anchor) {
        // Effectuer le défilement vers l'élément cible dans la modal
        anchor.scrollIntoView({behavior: 'smooth'});
      }
    },
  }
}
</script>
<style lang="css" scoped src="../components/modal.css"></style>