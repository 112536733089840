var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "imageModal",
          attrs: {
            id: "scroll",
            scrollable: "",
            centered: "",
            size: "xl",
            "hide-footer": "",
          },
          on: { hide: _vm.handleModalClose },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "close-btn",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.imageModal.hide()
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "x",
                          "aria-label": "Close",
                          variant: "dark",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "space-between" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-start",
                              attrs: {
                                cols: _vm.getTitleCols(),
                                sm: "12",
                                md: _vm.getTitleCols(),
                              },
                            },
                            [
                              _c(
                                "b-media",
                                {
                                  staticClass: "d-flex",
                                  scopedSlots: _vm._u([
                                    {
                                      key: "aside",
                                      fn: function () {
                                        return [
                                          _c("b-avatar", {
                                            attrs: {
                                              button: "",
                                              rounded: "sm",
                                              src: _vm.selectedImage.user
                                                .avatar,
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("div", { staticClass: "lh-sm" }, [
                                    _c(
                                      "h5",
                                      { staticClass: "m-0 fs-6 lh-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedImage.user.fullName
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "m-0 text-muted" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedImage.user.profession
                                          ) +
                                            " - " +
                                            _vm._s(
                                              _vm.selectedImage.user.location
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.selectedImage.title
                            ? [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { "white-space": "nowrap" },
                                    attrs: {
                                      cols: _vm.getTitleCols(),
                                      sm: "12",
                                      md: _vm.getTitleCols(),
                                    },
                                  },
                                  [
                                    _c("h5", { staticClass: "m-0 p-0 fs-6" }, [
                                      _vm._v(_vm._s(_vm.selectedImage.title)),
                                    ]),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-end btn-actions",
                              attrs: {
                                cols: _vm.getTitleCols(),
                                sm: "12",
                                md: _vm.getTitleCols(),
                              },
                            },
                            [
                              _vm.selectedImage.allowComments
                                ? [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "light" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getAnchorLink(
                                              "imageDown"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "me-1",
                                          attrs: {
                                            icon: "chat-left-dots",
                                            "font-scale": "0.9",
                                            "aria-label": "Like",
                                            variant: "dark",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.selectedImage.countComments
                                            ) +
                                            "\n                "
                                        ),
                                        _vm.selectedImage.countComments > 1
                                          ? [_vm._v("commentaires")]
                                          : [_vm._v("commentaire")],
                                      ],
                                      2
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "light" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getAnchorLink("imageDown")
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "me-1",
                                    attrs: {
                                      icon: "info-circle",
                                      "font-scale": "0.9",
                                      "aria-label": "Like",
                                      variant: "dark",
                                    },
                                  }),
                                  _vm._v("\n              Infos\n            "),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("viewer-image-common", {
            attrs: { selectedImage: _vm.selectedImage },
          }),
          _vm._v(" "),
          _c("div", { attrs: { id: "scroll-imageDown" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }